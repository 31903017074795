// React init
import React from "react";

// Material UI components
import {
  Avatar,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
  Hidden,
} from "@material-ui/core";

// Images
import sweetgreen from "../assets/images/graphiclogo.png";

// Colors
import { dogwood_gold } from "../assets/Palette";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "transparent",
    height: "100%",
    // marginLeft: "5%",
    // marginRight: "5%",
    paddingBottom: "5%",
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingTop: "5%",
    width: "100%",
  },
  onlineListing: {
    // color: "white",
    fontSize: "2vw",
    fontWeight: "600",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "8%",
    textAlign: "center",
  },
  buywiserRebate: {
    // color: "white",
    fontSize: "2vw",
    fontWeight: "100",
    marginLeft: "5%",
    marginRight: "5%",
    textAlign: "center",
  },
  buyerTestimony: {
    // color: "white",
    fontSize: "1.5vw",
    fontWeight: "300",
    marginBottom: "5%",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "5%",
    textAlign: "left",
  },
  mobileOnlineListing: {
    color: "#000000",
    fontSize: "4vh",
    fontWeight: "bold",
    // color: "#000000",
    // fontSize: "2.5vh",
    // fontWeight: "100",
    // marginTop: "5vh",
    textAlign: "center",
  },
  mobileBuywiserRebate: {
    // color: "white",
    color: "#000000",
    fontSize: "2.5vh",
    fontWeight: "100",
    marginTop: "2vh",
    textAlign: "center",
  },
  mobileBuyerTestimony: {
    // color: "white",
    color: "#000000",
    fontSize: "2.5vh",
    fontWeight: "100",
    marginTop: "2vh",
    textAlign: "left",
  },
}));

function CaseStudy() {
  const classes = useStyles();

  return (
    <>
      <Hidden smDown implementation="css">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item md={4} style={{ height: "100%" }}>
            <img
              src={sweetgreen}
              style={{
                display: "block",
                filter: "drop-shadow(5px 5px 5px #222)",
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            md={8}
            style={{
              backgroundColor: "transparent",
              borderBottomRightRadius: "2%",
              borderTopRightRadius: "2%",
              height: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={12}>
                <Typography className={classes.onlineListing}>
                  "Dogwood turned our vision into a reality"
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.buywiserRebate}>
                  -Jack Payne, CEO of MusicTiki
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography className={classes.buyerTestimony}>
                  When start-up MusicTiki was looking to implement their idea
                  for bringing people together with music and technology, they
                  turned to Dogwood to make their idea a reality.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={12} md={12}>
            <Typography className={classes.mobileOnlineListing}>
              "Dogwood turned our vision into a reality"
            </Typography>
          </Grid>
          <Grid item xs={12} d={12}>
            <Typography className={classes.mobileBuywiserRebate}>
              -Jack Payne, CEO of MusicTiki
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} style={{ height: "100%" }}>
            <img
              src={sweetgreen}
              style={{
                display: "block",
                filter: "drop-shadow(5px 5px 5px #222)",
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={classes.mobileBuyerTestimony}>
              When start-up MusicTiki was looking to implement their idea for
              bringing people together with music and technology, they turned to
              Dogwood to make their idea a reality.
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}

export default CaseStudy;
