import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { spacing } from "@material-ui/system";

import { dogwood_gold } from "./assets/Palette";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#000000",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    flex: "1 0 auto",
    margin: theme.spacing(1),
  },
  menuButton: {
    color: "#ffffff",
    transition: "1.0s",
    "&::after": {
      transition: `transform 1.0s linear`,
      transform: "scaleX(0)",
      transformOrigin: "right",
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: dogwood_gold,
    },
    ":hover::after": {
      transform: "scaleX(1)",
      transformOrigin: "left",
    },
  },
  footerText: {
    color: "#ffffff",
    textAlign: "center",
  },
}));

function BreakpointDown(props) {
  const classes = useStyles();
  const { width } = props;

  return (
    <div className={classes.root}>
      {/* <Typography variant="subtitle1">Current width: {width}</Typography> */}
      <div className={classes.container}>
        <div
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          //   style={{
          //     display: "grid",
          //     gridTemplateColumns: "repeat(3, 1fr)",
          //     gridGap: 20,
          //   }}
        >
          {/* desktop */}
          <Hidden smDown implementation="css">
            <Typography className={classes.footerText}>
              <span style={{ color: dogwood_gold }}> &copy; </span> 2020 Dogwood
              Technology Associates, LLC
              <span style={{ color: dogwood_gold }}> | </span>
              info@dogwoodtech.com
            </Typography>
          </Hidden>
          {/* mobile */}
          <Hidden mdUp implementation="css">
            <Typography className={classes.footerText}>
              <span style={{ color: dogwood_gold }}> &copy; </span> 2020 Dogwood
              Technology Associates, LLC
              <br />
              info@dogwoodtech.com
            </Typography>
          </Hidden>
        </div>

        <Paper elevation={0}></Paper>

        {/* <Hidden xsDown>
          <Paper className={classes.paper}>xsDown</Paper>
        </Hidden>
        <Hidden smDown>
          <Paper className={classes.paper}>smDown</Paper>
        </Hidden>
        <Hidden mdDown>
          <Paper className={classes.paper}>mdDown</Paper>
        </Hidden>
        <Hidden lgDown>
          <Paper className={classes.paper}>lgDown</Paper>
        </Hidden>
        <Hidden xlDown>
          <Paper className={classes.paper}>xlDown</Paper>
        </Hidden> */}
      </div>
    </div>
  );
}

BreakpointDown.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(BreakpointDown);

// import React from "react";
// import makeStyles from "@material-ui/core/styles/makeStyles";
// const useStyles = makeStyles((theme) => ({
//   //   button: {
//   //     flex: 1,
//   //     borderRadius: 4,
//   //     borderWidth: 0.5,
//   //     borderColor: "#d6d7da",
//   //     backgroundColor: "#2196F3",
//   //   },
//   button: {
//     alignSelf: "stretch",
//   },
// }));

// function Footer() {
//   return (
//     <div>
//       <p>This is footer</p>
//       <View style={{ flex: 1 }}>
//         <Button style={{ alignSelf: "stretch" }} />{" "}
//       </View>
//     </div>
//   );
// }

// export default Footer;
