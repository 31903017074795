// React init
import React from "react";

// Material UI components
import {
  Avatar,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { background, accent1, accent3 } from "../assets/Palette";

const useStyles = makeStyles((theme) => ({
  agentCarouselItem: {
    backgroundColor: "transparent",
    // backgroundColor: accent3,
    // height: "100%",
    // left: "50%",
    padding: "0% 2%",
    // position: "absolute",
    // top: "50%",
    // transform: "translate(-50%,-50%)",
    // width: "100%",
    height: "30vh",
    width: "15vw",
  },
  agentAvatar: {
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    position: "block",
    width: "50%",
  },
  agentName: {
    color: "#ffffff",
    // fontSize: "100%",
    fontSize: "90%",
    fontWeight: "bold",
    marginTop: "2%",
    textAlign: "center",
  },
  agentContact: {
    color: "#ffffff",
    fontSize: "80%",
    fontWeight: "100",
    marginBottom: "6%",
    marginTop: "2%",
    textAlign: "center",
  },
  agentLink: {
    // backgroundColor: "rgba(212, 240, 254, 0.5)",
    // backgroundColor: "rgba(34, 156, 243, 0.4)",
    backgroundColor: "rgba(1,53,79,0.4)",
    borderColor: accent1,
    borderStyle: "solid",
    borderWidth: "0.10vw",
    color: accent1,
    fontSize: "90%",
    fontWeight: "bold",
    paddingLeft: "15%",
    paddingRight: "15%",
    textTransform: "none",
  },
}));

function AgentAdvertisement(props) {
  const classes = useStyles();

  const { agentPhoto, agentName, agentContact } = props;

  return (
    <Paper
      elevation={0}
      className={classes.agentCarouselItem}
      style={{ marginRight: 0 }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        // style={{ height: "100%", width: "100%" }}
      >
        <Grid item md={12}>
          <Avatar src={agentPhoto} className={classes.agentAvatar} />
        </Grid>
        <Grid item md={12}>
          <Typography className={classes.agentName}>{agentName}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography className={classes.agentContact}>
            {agentContact}
          </Typography>
        </Grid>
        <Grid item md={12} style={{ textAlign: "center" }}>
          <Button className={classes.agentLink}>Learn more</Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AgentAdvertisement;
