// React init
import React from "react";
import { useEffect } from "react";

// Material UI components
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Link,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Images
import heroBroker from "../assets/images/hero-broker.jpg";

// Colors
import { dogwood_gold } from "../assets/Palette";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "inherit",
    color: "white",
    fontFamily: "Lato",
    minHeight: "100%",
    overflow: "hidden",
    paddingBottom: "2vh",
    paddingTop: "2vh",
  },
  stakeholderTitle: {
    color: "inherit",
    fontSize: "4vh",
    textAlign: "center",
  },
  congratulationText: {
    color: "inherit",
    fontSize: "2vh",
    marginTop: "5vh",
  },
  formText: {
    marginBottom: "2vh",
    fontSize: "20rem",
    width: "80%",
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function LoadingScreen(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const { handleLoadingChange } = props;

  useEffect(() => {
    const interval = setInterval(() => {
      setLoading(false);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%", width: "100%" }}
    >
      <Typography className={classes.stakeholderTitle}>
        Verifying credential status
      </Typography>
      <Grid item xs={12} align="center" style={{ marginTop: "5vh" }}>
        <CircularProgress size={80} style={{ color: "#0080ff" }} />
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%", width: "100%" }}
    >
      <Typography className={classes.stakeholderTitle}>
        Invalid credentials
      </Typography>
      <Grid item xs={12} align="center">
        <Typography className={classes.congratulationText}>
          If you forgot your password, contact your account manager or
          security@dogwoodtech.com. Never send credentials in emails.
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          style={{ marginTop: "10vh" }}
          className={classes.button}
          size={"large"}
          variant="contained"
          onClick={() => {
            handleLoadingChange(false);
          }}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  );
}

export default function BrokerForm() {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const handleLoadingChange = (newLoadingStatus) => {
    console.log("loading" + loading);
    setLoading(newLoadingStatus);
  };

  const form = (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid item md={12} align="center">
        <Typography className={classes.stakeholderTitle}>
          Client Login
        </Typography>
        <Paper
          elevation={0}
          style={{
            borderBottom: "5px solid #0080ff",
            width: "23%",
            marginTop: "3vh",
            marginBottom: "1vh",
          }}
        ></Paper>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl className={classes.formText}>
          <InputLabel style={{ fontSize: "1.5rem" }} htmlFor="firstNameInput">
            Username/email *
          </InputLabel>
          <Input id="firstNameInput" aria-describedby="my-helper-text" />
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center">
        <FormControl className={classes.formText}>
          <InputLabel style={{ fontSize: "1.5rem" }} htmlFor="lastNameInput">
            Password *
          </InputLabel>
          <Input id="lastNameInput" aria-describedby="my-helper-text" />
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          className={classes.button}
          style={{ marginTop: "2vh" }}
          size={"large"}
          variant="contained"
          onClick={() => {
            handleLoadingChange(true);
          }}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      {!loading ? (
        form
      ) : (
        <LoadingScreen handleLoadingChange={handleLoadingChange} />
      )}
      {loading}
    </div>
  );
}
