// React init
import React, { useRef } from "react";

// Material UI core
import {
  Avatar,
  Button,
  Fab,
  Grid,
  IconButton,
  InputBase,
  Link,
  Paper,
  Typography,
  Hidden,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";

// Material UI icons
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DownArrow from "@material-ui/icons/ExpandMore";

// React Reveal
import Fade from "react-reveal/Fade";
import Flash from "react-reveal/Flash";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";

// Typist
import Typist from "react-typist";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Components
import AgentAdvertisment from "../components/AgentAdvertisement";
import HomeAdvertisment from "../components/HomeAdvertisement";
import CaseStudy from "../components/CaseStudy";
import ContactForm from "../components/ContactForm";
import DesktopNavbar from "../navigation/DesktopNavbar";
import MobileNavbar from "../components/MobileNavbar";

// Images
import hero from "../assets/images/heroCompressed.jpeg";
import sweetgreen from "../assets/images/sweetgreen.jpg";
import SkeenHeadshot from "../assets/images/SkeenHeadshot.jpg";
import GivingBack from "../assets/images/givingBack.jpg";
import MovingIn from "../assets/images/MovingIn.jpg";
import LowerRate from "../assets/images/lowerRate.jpg";
import DataIcon from "../assets/images/datascienceicon.png";

import LA from "../assets/images/LA.jpg";
import Team from "../assets/images/team.jpg";
import Approach from "../assets/images/approach.jpg";

import CloudIcon from "../assets/images/cloudicon.png";
import WebIcon from "../assets/images/webappicon.png";
import MobileIcon from "../assets/images/mobileappicon.png";
import CyberIcon from "../assets/images/cybersecurityicon.png";
import DBIcon from "../assets/images/databaseicon.png";

// Logos
import Zillow from "../assets/logos/zillow2.jpeg";
import Redfin from "../assets/logos/redfin2.jpeg";
import Trulia from "../assets/logos/trulia2.jpeg";
import Realtor from "../assets/logos/realtordotcom2.png";

// Colors
import {
  foreground,
  background,
  accent1,
  accent2,
  accent3,
  dogwood_gold,
} from "../assets/Palette";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    color: "#000000",
    overflow: "hidden",
  },
  content: {
    lineHeight: 0,
    marginLeft: "10vw",
    marginRight: "10vw",
  },
  firstItem: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  secondItem: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  parallaxContent: {
    // backgroundImage: `url(${heroHouse})`,
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // backgroundColor: "rgba(1,53,79)", //"#229cf3",
    // marginBottom: "20vh",
    // marginTop: "20vh",
    paddingBottom: "10vh",
    paddingLeft: "10vw",
    paddingRight: "10vw",
    paddingTop: "5vh",
    height: "50vh",

    // background:
    //   "linear-gradient(10deg, rgba(0,43,64,1) 0%, rgba(0,52,79,1) 27%, rgba(212,240,254,1) 91%)",
    background:
      "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 46%, rgba(219,219,219,1) 100%)",
  },
  heroContainer: {
    backgroundImage: `url(${hero})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "inherit",
    height: "105vh",
    width: "100vw",
  },
  firstLine: {
    color: "white",
    fontFamily: "inherit",
    fontSize: "2.0rem",
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "25%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    width: "80vw",
  },
  secondLine: {
    color: "white",
    fontFamily: "inherit",
    fontSize: "2.0rem",
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "35%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    width: "100vw",
  },
  thirdLine: {
    color: "white",
    fontFamily: "inherit",
    fontSize: "2.0rem",
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "45%",
    transform: "translate(-50%,-50%)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    width: "100vw",
  },
  downArrowButton: {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: dogwood_gold,
    left: "50%",
    position: "absolute",
    top: "55%",
    transform: "translate(-50%,-50%)",
    "&:hover": {
      backgroundColor: "rgba(230, 187, 81, 0.3)",
      color: "white",
    },
  },
  extensionPrompt: {
    // backgroundColor: "rgba(212, 240, 254, 0.9)",
    // backgroundColor: "#d5d5d5",
    backgroundColor: background,
    color: accent1,
    fontSize: "1.5vw",
    fontWeight: "bold",
    left: "49.2%",
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    // textTransform: "none",
    // top: "20%",
    top: "55.0%",
    transform: "translate(-50%,-50%)",
    // width: "60vw",
    width: "20vw",
    "&:hover": {
      color: background,
      //   backgroundColor: accent3,
    },
  },
  heroTitle: {
    color: "black",
    // color: "white",
    fontSize: "1.3rem",
    fontWeight: "600",
  },
  heroSearchBar: {
    backgroundColor: "#ffffff",
    border: "none",
    height: "5vh",
    width: "100%",
  },
  heroSearchInput: {
    height: "100%",
    marginLeft: "0.80vw",
    width: "93%",
  },
  heroRedirect: {
    backgroundColor: "rgba(212, 240, 254, 0.7)",
    borderColor: "#595959",
    borderStyle: "solid",
    borderWidth: "0.10vw",
    height: "20vh",
    left: "50%",
    paddingBottom: "3%",
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingTop: "1%",
    position: "absolute",
    // top: "45%",
    top: "40%",
    transform: "translate(-50%,-50%)",
    // width: "80vw",
    width: "60vw",
  },
  heroRedirectPrompt: {
    // color: "white",
    color: "black",
    fontSize: "1.3rem",
    fontWeight: 900,
    textAlign: "center",
  },
  redirectButton: {
    alignItems: "center",
    // backgroundColor: accent1,
    backgroundColor: "transparent",
    display: "flex",
    height: "15vh",
    justifyContent: "center",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  redirectLogo: {
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%,-50%)",
    width: "80%",
  },
  agentCarouselContainer: {
    height: "20vh",
    left: "50%",
    // marginLeft: "5%",
    // marginRight: "10%",
    paddingRight: "5%",
    position: "relative",
    top: "75%",
    transform: "translate(-50%,-50%)",
  },
  agentCarousel: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  agentCarouselTrack: {
    marginLeft: "5%",
    marginRight: "5%",
    // paddingLeft: "5%",
    // paddingRight: "5%",
  },
  agentCarouselItem: {
    // height: "25vh",
    marginLeft: "0.01vw",
    marginRight: "0.02vw",
    // paddingLeft: "0.1vw",
    // paddingRight: "0.1vw",
    // width: "15vw",
  },
  benefitItem: {
    color: "inherit",
    backgroundColor: "transparent",
    height: "auto",
    marginTop: "5vh",
    marginBottom: "5vh",
    width: "80%",
  },
  benefitTitle: {
    color: "#000000",
    fontSize: "2.5vh",
    fontWeight: "bold",
    marginTop: "2vh",
  },
  benefitDescription: {
    color: "#000000",
    fontSize: "2.5vh",
    fontWeight: "100",
    marginTop: "2vh",
  },
  dealCarousel: {
    marginTop: "auto",
    marginBottom: "auto",
    display: "block",
  },
  stakeholderTitle: {
    color: "#000000",
    fontSize: "4vh",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3vh",
    },
  },
  stakeholderDescription: {
    color: "#000000",
    fontSize: "2.5vh",
    fontWeight: "100",
    marginTop: "5vh",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5vh",
      marginTop: "2.5vh",
    },
  },
}));

const agentCarouselLeftArrow = (
  <Fab
    variant="outlined"
    color={background}
    style={{
      color: background,
      backgroundColor: "rgba(212, 240, 254, 0.8)",
      borderColor: background,
      //   borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: "0.15vw",
      height: "5vh",
      //   paddingLeft: "2%",
      //   paddingRight: "2%",
      position: "absolute",
      left: "0%",
      textAlign: "center",
    }}
  >
    <ArrowBackIosIcon
      style={{
        fontSize: "1.7vw",
        fontWeight: "800",
        left: "60%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />
  </Fab>
);

const agentCarouselRightArrow = (
  <Fab
    variant="outlined"
    color={background}
    style={{
      color: background,
      backgroundColor: "rgba(212, 240, 254, 0.8)",
      borderColor: background,
      //   borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: "0.15vw",
      height: "5vh",
      //   paddingLeft: "2%",
      //   paddingRight: "2%",
      position: "absolute",
      right: "-0.01%",
      textAlign: "center",
    }}
  >
    <ArrowForwardIosIcon
      style={{
        fontSize: "1.7vw",
        fontWeight: "800",
        left: "55%",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />
  </Fab>
);

function GetTrigger(props, thresh) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: thresh,
    target: window ? window() : undefined,
  });

  return trigger;
}

export default function HomePage(props) {
  const classes = useStyles();
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const experienceRef = useRef(null);
  const contactRef = useRef(null);

  const [renderedFirstLine, setFirstLine] = React.useState(false);
  const [renderedSecondLine, setSecondLine] = React.useState(false);
  const [finishedSecondLine, setFinishedSecondLine] = React.useState(false);
  const [renderedThirdLine, setThirdLine] = React.useState(false);

  const scrollToRef = (refToScrollTo) => {
    console.log("Keys ", Object.keys(refToScrollTo));
    refToScrollTo.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Hidden smDown implementation="css">
        <DesktopNavbar
          scrollToHome={() => {
            scrollToRef(homeRef);
          }}
          scrollToServices={() => {
            scrollToRef(servicesRef);
          }}
          scrollToExperience={() => {
            scrollToRef(experienceRef);
          }}
          scrollToContact={() => {
            scrollToRef(contactRef);
          }}
        />
      </Hidden>
      <Hidden mdUp implementation="css">
        <MobileNavbar
          scrollToHome={() => {
            scrollToRef(homeRef);
          }}
          scrollToServices={() => {
            scrollToRef(servicesRef);
          }}
          scrollToExperience={() => {
            scrollToRef(experienceRef);
          }}
          scrollToContact={() => {
            scrollToRef(contactRef);
          }}
        />
      </Hidden>
      <div className={classes.root} ref={homeRef}>
        <Paper square className={classes.heroContainer}>
          <Typist
            startDelay={1500}
            avgTypingDelay={40}
            onTypingDone={() => {
              setFirstLine(true);
            }}
            cursor={{ show: false }}
            className={classes.firstLine}
          >
            Welcome to Dogwood Technology Associates
          </Typist>
          {renderedFirstLine ? (
            <Typist
              startDelay={1000}
              avgTypingDelay={40}
              onLineTyped={() => {
                if (!renderedSecondLine) setSecondLine(true);
              }}
              onTypingDone={() => {
                setFinishedSecondLine(true);
              }}
              cursor={{ show: false }}
              className={classes.secondLine}
            >
              We develop{" "}
              <span style={{ color: "white", textDecoration: "underline" }}>
                software
              </span>
              <Typist.Backspace delay={4000} count={8} />
              <span style={{ color: "white", textDecoration: "underline" }}>
                web apps
              </span>
              <Typist.Backspace delay={1400} count={8} />
              <span style={{ color: "white", textDecoration: "underline" }}>
                mobile apps
              </span>
              <Typist.Backspace delay={1400} count={11} />
              <span style={{ color: "white", textDecoration: "underline" }}>
                firmware
              </span>
              <Typist.Backspace delay={1400} count={8} />
              <span style={{ color: "white", textDecoration: "underline" }}>
                analytics tools
              </span>
              <Typist.Backspace delay={1400} count={15} />
              <span style={{ color: "white", textDecoration: "underline" }}>
                security tools
              </span>
              <Typist.Backspace delay={1400} count={14} />
              <span style={{ color: "white", textDecoration: "none" }}>
                everything
              </span>
            </Typist>
          ) : null}
          {renderedSecondLine ? (
            <Typist
              startDelay={1000}
              avgTypingDelay={40}
              onTypingDone={() => {
                setThirdLine(true);
              }}
              cursor={{ show: false }}
              className={classes.thirdLine}
            >
              Discover how we can help you
            </Typist>
          ) : null}
          <Fab
            color="primary"
            className={classes.downArrowButton}
            onClick={() => {
              scrollToRef(servicesRef);
            }}
          >
            <Fade right when={renderedThirdLine} timeout={1500}>
              <Flash when={finishedSecondLine}>
                <DownArrow
                  fontSize="large"
                  style={{ color: "white", marginTop: "5%" }}
                />
              </Flash>
            </Fade>
          </Fab>
        </Paper>
        <div ref={servicesRef}></div>
        <div
          className={classes.content}
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 50%, rgba(219,219,219,1) 100%)",
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: "10vw",
            paddingRight: "10vw",
            paddingTop: "10vh",
          }}
        >
          <Fade right timeout={2000}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              {/* Home buyer benefits section */}
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={WebIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Web Development
                    </Typography>
                    {/* <Button
                      onClick={() => {
                        scrollToRef(servicesRef);
                      }}
                      title={"Test"}
                    >
                      Test
                    </Button> */}
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      Desktop and mobile friendly pages to market your business
                      and service your customers.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={MobileIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Mobile Development
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      iOS and Android apps; fluid products that are easy to use
                      and tailored to your audience.
                    </Typography>
                  </Grid>
                </Grid>
                {/* </Paper> */}
              </Grid>
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={CyberIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Cyber Security
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      Vulnerability discovery for your business’s databases,
                      networks, applications, and embedded devices.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
          <Fade right timeout={2000}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
            >
              {/* Home buyer benefits section */}
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={CloudIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Cloud Solutions
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      Migrating your business to the cloud and taking advantage
                      of serverless infrastructure and SaaS applications.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={DataIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Data Science
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      Machine learning, data analytics, and platform development
                      to identify trends and predict outcomes.
                    </Typography>
                  </Grid>
                </Grid>
                {/* </Paper> */}
              </Grid>
              <Grid item md={4}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={0}
                  style={{ height: "100%", marginTop: "10%", width: "100%" }}
                >
                  <Grid
                    item
                    md={12}
                    style={{
                      height: "50%",
                      justifyItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={DBIcon}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid md={12} style={{ height: "10%", width: "100%" }}>
                    <Typography className={classes.benefitTitle}>
                      Databases
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ height: "40%", width: "100%" }}>
                    <Typography className={classes.benefitDescription}>
                      SQL, NoSQL, and Teradata database creation, optimization,
                      query, and maintenance.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        </div>
        <div
          className={classes.content}
          style={{
            background:
              "linear-gradient(180deg, rgba(219,219,219,1) 0%, rgba(240,240,240,1) 50%, rgba(255,255,255,1) 100%)",
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: "10vw",
            paddingRight: "10vw",
            paddingTop: "10vh",
          }}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={5}
          >
            <Fade right timeout={2000}>
              <Grid item md={12}>
                <div ref={experienceRef}></div>
                <CaseStudy
                  agentPhoto={SkeenHeadshot}
                  agentName={"Daniel Skeen and Team"}
                  agentContact={"(571) 239-7050"}
                />
              </Grid>
            </Fade>
            {/* Agent benefits */}
            <Grid item md={12}>
              <Fade right timeout={2000}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={5}
                  style={{ height: "100%", width: "100%" }}
                >
                  <Grid item md={6}>
                    <img
                      src={LA}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.stakeholderTitle}>
                      Our Identity
                    </Typography>
                    <Typography className={classes.stakeholderDescription}>
                      Dogwood Technology Associates is a technology consulting
                      firm based out of Los Angeles, California. We are a team
                      of problem solvers that combines software expertise,
                      engineering skill, and business acumen to develop
                      solutions that meet our clients’ needs.
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            {/* Lender benefits */}
            <Grid item md={12}>
              <Fade left timeout={2000}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={5}
                  style={{
                    height: "100%",
                    marginTop: "5vh",
                    marginBottom: "5vh",
                    width: "100%",
                  }}
                >
                  <Grid item md={6} className={classes.firstItem}>
                    <Typography className={classes.stakeholderTitle}>
                      Our Team
                    </Typography>
                    <Typography className={classes.stakeholderDescription}>
                      We are characterized by a hunger to solve interesting
                      problems in diverse spaces, driving us to take on
                      challenges in aerospace, defense, finance, media,
                      insurance, branding, enterprise software, and more.
                    </Typography>
                  </Grid>
                  <Grid item md={6} className={classes.secondItem}>
                    <img
                      src={Team}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            {/* Beneficiary benefits */}
            <Grid item md={12}>
              <Fade right timeout={2000}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={5}
                  style={{
                    height: "100%",
                    width: "100%",
                    marginBottom: "15vh",
                  }}
                >
                  <Grid item md={6}>
                    <img
                      src={Approach}
                      style={{
                        display: "block",
                        height: "100%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography className={classes.stakeholderTitle}>
                      Our Approach
                    </Typography>
                    <Typography className={classes.stakeholderDescription}>
                      We firmly believe in starting our work by gaining a deep
                      understanding of our clients’ organization and the
                      challenges they face. We work closely with our clients to
                      develop the technology to overcome those challenges,
                      providing full transparency into our progress and adapting
                      to feedback every step along the way.
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
            <div ref={contactRef}></div>
          </Grid>
          <Grid md={12}>
            <Fade up>
              <ContactForm />
            </Fade>
          </Grid>
        </div>
      </div>
    </>
  );
}
