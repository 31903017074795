// React init
import React from "react";

// Material UI components
import {
  Avatar,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import sweetgreen from "../assets/images/graphiclogo.png";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "transparent",
    height: "100%",
    // marginLeft: "5%",
    // marginRight: "5%",
    paddingBottom: "5%",
    paddingLeft: "3%",
    paddingRight: "3%",
    paddingTop: "5%",
    width: "100%",
  },
  onlineListing: {
    fontSize: "2vw",
    fontWeight: "bold",
    marginLeft: "10%",
    marginTop: "8%",
  },
  buywiserRebate: {
    color: "green",
    fontSize: "2vw",
    marginLeft: "10%",
  },
  buyerTestimony: {
    fontSize: "1.2vw",
    fontWeight: "100",
    marginBottom: "5%",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "5%",
    textAlign: "center",
  },
  agentAvatar: {
    height: "50%",
    marginBottom: "8%",
    marginLeft: "10%",
    marginRight: "10%",
    position: "block",
    width: "50%",
  },
  agentName: {
    color: "#000000",
    fontSize: "100%",
    fontWeight: "bold",
    marginBottom: "2%",
    textAlign: "right",
  },
  agentContact: {
    color: "#000000",
    fontSize: "80%",
    fontWeight: "100",
    marginBottom: "2%",
    textAlign: "right",
  },
  agentLink: {
    fontSize: "90%",
    marginBottom: "8%",
    textAlign: "center",
  },
}));

function AgentAdvertisement(props) {
  const classes = useStyles();

  const { agentPhoto, agentName, agentContact } = props;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item md={6} style={{ height: "100%" }}>
        <img
          src={sweetgreen}
          style={{
            borderBottomLeftRadius: "2%",
            borderTopLeftRadius: "2%",
            display: "block",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        md={6}
        style={{
          backgroundColor: "white",
          borderBottomRightRadius: "2%",
          borderTopRightRadius: "2%",
          height: "100%",
        }}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={12}>
            <Typography className={classes.onlineListing}>
              Online listing: $550,432
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.buywiserRebate}>
              Solace rebate: $8,745
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.buyerTestimony}>
              "I was able to use the Solace rebate to reduce my loan rate and
              support my son's new middle school!"
            </Typography>
          </Grid>
          <Grid item md={4} />
          <Grid item md={4}>
            <Typography className={classes.agentName}>{agentName}</Typography>
            <Typography className={classes.agentContact}>
              {agentContact}
            </Typography>
            <Grid container justify="flex-end" alignItems="center">
              <Link className={classes.agentLink}>Get in touch</Link>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Avatar src={agentPhoto} className={classes.agentAvatar} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AgentAdvertisement;
