// React init
import React from "react";
import "./App.css";

// Material UI components
import makeStyles from "@material-ui/core/styles/makeStyles";

//Pages
import HomePage from "./pages/HomePage";
import FootBar from "./Footbar.js";
import LoginPage from "./pages/LoginPage";

// Navigation
import DesktopNavbar from "./navigation/DesktopNavbar";
import MobileNavbar from "./components/MobileNavbar";

//Components
import { Hidden } from "@material-ui/core";

//Utils
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    color: "#000000",
    overflow: "hidden",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Router>
        <ScrollToTop></ScrollToTop>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/*">
            <HomePage />
          </Route>
        </Switch>
        <FootBar />
      </Router>
    </div>
  );
}

export default App;
