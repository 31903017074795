import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Images
import toolbar_logo from "../assets/images/gold_logo.png";

import { Link } from "react-router-dom";

const dogwood_gold = "#E6BB51";
const solaceblue = "#282c34";
const solacesecondaryblue = "#229cf3";
const solacedarkblue = "rgba(1,53,79,0.8)";
const dogwood_silver = "#807B80";
const charcoal = "#36454f";
const off_charcoal = "#3e4f5b";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navbar: {
    // alignItems: "center",
    color: "#229cf3",
    backgroundColor: "#229cf3",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    width: "20%",
  },
  toolbarLogo: {
    height: "100%",
    width: "100%",
    color: dogwood_gold,
  },
  buttonContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  pageButton: {
    color: "inherit",
    fontSize: "1.1rem",
    fontFamily: "inherit",
    margin: "auto 0.8vw",
    transition: "1.0s",
    "&::after": {
      transition: `transform 1.0s linear`,
      transform: "scaleX(0)",
      transformOrigin: "right",
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: dogwood_gold,
    },
    ":hover::after": {
      transform: "scaleX(1)",
      transformOrigin: "left",
    },
    textTransform: "none",
  },
  loginButton: {
    color: "inherit",
    fontSize: "1.1rem",
    fontFamily: "inherit",
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: "2px",
    borderRadius: "8px",
    borderStyle: "solid",
    borderColor: `${dogwood_gold}`,
    // margin: "auto 0.5vw",
  },
  loginTitle: {
    color: "#fffff",
    fontWeight: "bold",
  },
}));

function ColorOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 8 : 0,
    style: {
      color: trigger ? "white" : "white", //"rgba(0,0,0,1.0)", //dogwood_gold : "white",
      backgroundColor: trigger ? "rgba(0,0,0,0.9)" : "transparent",
    },
  });
}

ColorOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function ButtonAppBar(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <ColorOnScroll {...props}>
        <AppBar className={classes.navbar} position="fixed">
          <Toolbar>
            <Button
              onClick={() => {
                props.scrollToHome();
              }}
              edge="start"
              className={classes.menuButton}
            >
              <img
                src={toolbar_logo}
                alt="toolbar logo"
                className={classes.toolbarLogo}
              />
            </Button>
            <div className={classes.buttonContainer}>
              <Button
                onClick={() => {
                  props.scrollToServices();
                }}
                disableFocusRipple
                disableRipple
                className={classes.pageButton}
              >
                Services
              </Button>
              <Button
                onClick={() => {
                  props.scrollToExperience();
                }}
                disableFocusRipple
                disableRipple
                className={classes.pageButton}
              >
                About Us
              </Button>
              <Button
                onClick={() => {
                  props.scrollToContact();
                }}
                disableFocusRipple
                disableRipple
                className={classes.pageButton}
              >
                Contact
              </Button>
              <Button
                component={Link}
                to={"/login"}
                disableFocusRipple
                disableRipple
                className={classes.pageButton}
              >
                Login
              </Button>
            </div>
            <div style={{ fontFamily: "Roboto" }}>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  id="form-dialog-title"
                  className={classes.loginTitle}
                  style={{ fontFamily: "Roboto", fontWeight: "bold" }}
                >
                  Login to your client page
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    If you forgot your credentials, contact your account
                    executive or email info@dogwoodtech.com. Never send any
                    private credentials in emails.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Username or email"
                    type="email"
                    fullWidth
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Password"
                    type="password"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Login
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Toolbar>
        </AppBar>
      </ColorOnScroll>
    </div>
  );
}
