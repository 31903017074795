// React init
import React from "react";

// Material UI core
import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Material UI icons
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Components
import AgentAdvertisment from "../components/AgentAdvertisement";
import HomeAdvertisment from "../components/HomeAdvertisement";
import CaseStudy from "../components/CaseStudy";
import LoginForm from "../components/LoginForm";

// Images
import hero from "../assets/images/heroCompressed.jpeg";
import toolbar_logo from "../assets/images/gold_logo.png";

// Colors
import {
  foreground,
  background,
  accent1,
  accent2,
  accent3,
} from "../assets/Palette";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // background:
    //   "linear-gradient(135deg, rgba(0,0,204,1) 0%, rgba(0,128,255,1) 50%, rgba(0,0,204,1) 100%)",
    backgroundImage: `url(${hero})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#000000",
    height: "100vh",
    overflow: "hidden",
    width: "100vw",
  },
  loginContainer: {
    backgroundColor: "transparent",
    height: "30vh",
    left: "50%",
    position: "absolute",
    top: "40%",
    transform: "translate(-50%,-50%)",
    width: "40vw",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  loginHeader: {
    fontSize: "1.3rem",
  },
  homeButton: {
    left: "50%",
    position: "absolute",
    top: "5%",
    transform: "translate(-50%,-50%)",
    height: "10vh",
    width: "30vw",
    [theme.breakpoints.down("sm")]: {
      width: "60vw",
    },
  },
}));

export default function HomePage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button component={Link} to={"/"} className={classes.homeButton}>
        <img
          src={toolbar_logo}
          alt="toolbar logo"
          style={{ height: "100%", width: "100%" }}
        />
      </Button>
      <div className={classes.loginContainer}>
        <LoginForm />
      </div>
    </div>
  );
}
