// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react";
// Material UI core
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;

    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/xzbjykrl"
        method="POST"
        style={{
          display: "block",
          height: "100%",
          marginLeft: "auto",
          marginRight: "auto",
          width: "80%",
        }}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={12}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "4vh",
                fontWeight: "bold",
                marginBottom: "2.5vh",
              }}
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item md={2} />
          <Grid item md={8}>
            <TextField
              id="email-field"
              name="email-field"
              label="Email"
              variant="outlined"
              style={{ marginBottom: "2vh", width: "100%" }}
            />
          </Grid>
          <Grid item md={2} />
          <Grid item md={2} />
          <Grid item md={8}>
            <TextField
              id="message-field"
              name="message-field"
              label="Message"
              multiline={true}
              rows={6}
              variant="outlined"
              style={{ marginBottom: "2vh", width: "100%" }}
            />
          </Grid>
          <Grid item md={2} />
          <Grid item md={2} />
          <Grid item md={8} style={{ marginBottom: "10vh", width: "100%" }}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {status === "SUCCESS" ? (
                <p>Thanks!</p>
              ) : (
                <button
                  style={{
                    fontSize: "2vh",
                    height: "5vh",
                    paddingLeft: "2vw",
                    paddingRight: "2vw",
                  }}
                >
                  Submit
                </button>
              )}
              {status === "ERROR" && <p>Ooops! There was an error.</p>}
            </Grid>
          </Grid>
          <Grid item md={2} />
        </Grid>
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
